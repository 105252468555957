<template>
  <div el="list md">
    <div el="list-column md">
      <div><input type="text" placeholder="Search..." class="searchInput" el></div>
      <div>
        <div el="btn" @click="connectCard">Add Credit Card</div>
      </div>
    </div>
    <div el="list xxsm" class="creditCardList">
        <div el="list-column-between p-xsm" v-for="(item, index) in cardList" class="creditCard">
          <div el="list">
            <p el="p">{{ item.name }}</p>
            <p el="p" class="smallTxt">{{ item.issuer }}</p>
          </div>  
          <div el="list-column xxsm" class="controls">
            <span class="material-icons" @click="editCard">edit</span>
            <span class="material-icons" @click="removeCard(item, index)">close</span>
          </div>
        </div>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue';
import dialog from "@/components/dialog.js";

let d = new Date();
let cardList = reactive([
  {
    name:'Rewards Plus Credit Card',
    issuer:'Bank of America',
    nickname:'BoA',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1, // $1
  },
  {
    name:'Money Back Credit Card',
    issuer:'Chase',
    nickname:'Chase Card',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1, // $1
  },
  {
    name:'Cash Back Credit Card',
    issuer:'Navy Federal',
    nickname:'Navy Fed',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.09, // $1
  },
]);

const connectCard = ()=>{
  dialog.title('Add Credit Card').alert(`We have successfully added a credit card to your account!`);
  let d = new Date();
  cardList.push({
    name:'Credit Card '+cardList.length,
    issuer:'Bank Name',
    nickname:'Nickname',
    createdOn: d,
    lastUpdated: d,
    expires: d,
    cardNumber:'4000123445671234',
    securityCode:'001',
    chargeFrequency:'monthly', // bi-weekly, monthly, quarterly, annually
    chargeAmount: 1.09, // $1
  });
};

const editCard = ()=>{
  dialog.title('Edit Credit Card').confirm(`Are you sure you want to edit this credit card?`);
};

const removeCard = async (item, index)=>{
  let res = await dialog.title('Delete Credit Card').confirm(`Are you sure you want to delete ${item.name} from ${item.issuer}?`);
  if(res == true) {
    cardList.splice(index,1);
    dialog.title('Deleted Successfully').alert(`We have successfully removed ${item.name} from your account.`);
  }
};

</script>
<style lang="scss">
.searchInput{
  padding:0px 16px;
  height:45px;
  border-radius:80px;
  --bg-color:white;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
}
.smallTxt{
  font-size:12px;
  font-style: italic;
}
.creditCardList{
  max-width:700px;
}
.creditCardList .material-icons{
  cursor: pointer;
}
.creditCard{
  border-radius:8px;
  --bg-color:white;
  box-shadow: 0 10px 20px hsla(0, 0%, 0%, 0.07);
}
.creditCard .controls{

}
</style>
